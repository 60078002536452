* {
    box-sizing: border-box;
}

body{
    margin: 0;
}

.div{
    text-align: left;
}

.p{
    text-align: left;
}

.img{
    max-width: max-content;
    height: auto;
}