* {
    box-sizing: border-box;
}

body{
    margin: 0;
}

.p {
    text-align: left;
}

.container {
    margin: 1rem;
    text-align: center;
}

.nav{
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 0 1rem;
}

.site-title{
    font-size: 2rem;
}

.nav ul{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.nav a{
    color: inherit;
    text-decoration: 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.25rem;
}

.nav li:active {
    background-color: #555;
}

.nav li:hover {
    color:rgba(0, 128, 255, 0.708);
}

